import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: { name: 'transcriptions' },
    meta: {
      title: 'Login',
      isRequiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: 'Login',
      isRequiresAuth: false
    },
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  },
  {
    path: '/transcriptions',
    name: 'transcriptions',
    meta: {
      title: 'Transcriptions',
      isRequiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "transcription" */ '../views/TranscriptionView.vue')
  },
  {
    path: '/upload',
    name: 'upload',
    meta: {
      title: 'Upload',
      isRequiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "upload" */ '../views/UploadView.vue')
  },
  {
    path: '/transcription/detail',
    name: 'transcription detail',
    meta: {
      title: 'Transcription Detail',
      isRequiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "transcriptionDetails" */ '../views/TranscriptionDetail.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.state.auth.isAuthenticated
  if (to.name !== 'login' && !isAuthenticated) next({ name: 'login' })
  if (to.name === 'login' && isAuthenticated) next({ name: 'transcriptions' })
  else next()
})

export default router
