import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'
const ls = new SecureLS({ isCompression: false })

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    auth: {
      isAuthenticated: false,
      exp: null,
      token: null
    },
    user: {
      name: null,
      img: null,
      email: null
    }
  },
  getters: {
  },
  mutations: {
    isAuthenticated (state, value) {
      state.auth.isAuthenticated = value
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState({
    storage: {
      getItem: (key) => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: (key) => ls.remove(key)
    }
  })]
})
